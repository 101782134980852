/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"
import React from "react"
import { Global } from "@emotion/core"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Grid, Box } from "theme-ui"
import HomeSlider from "../components/homeSlider"
import NavMenuMobile from "../components/navMenuMobile"
import NavMenuDesktop from "../components/navMenuDesktop"
import HomeNewsItem from "../components/homeNewsItem"
import Footer from "../components/footer"
import SEO from "../components/seo"
import { FaArrowDown } from "react-icons/fa"
import { Link, animateScroll as scroll } from "react-scroll"
//focus-ring polyfill
import "focus-visible/dist/focus-visible.js"
import GatsbyLink from "gatsby-link"

export default function Home({ data }) {
  //sort slider data
  const productionItems = data.productions.edges.filter(item => {
    return data.home.frontmatter.slider.indexOf(item.node.frontmatter.name) >= 0
  })
  //ordering can be found in indexpage of sfx site.
  //https://gist.github.com/ecarter/1423674 - hardcoded as i couldn't get key to work
  const mapOrder = (array, order, key) => {
    array.sort((a, b) => {
      const A = a.node.frontmatter.name
      //console.log(A)
      const B = b.node.frontmatter.name
      //console.log(B)
      return order.indexOf(A) > order.indexOf(B) ? 1 : -1
    })
    return array
  }

  const orderedProductionItems = mapOrder(
    productionItems,
    data.home.frontmatter.slider,
    "node.frontmatter.name"
  )
  console.log(orderedProductionItems)
  return (
    <>
      <Global
        styles={theme => ({
          ".js-focus-visible :focus:not(.focus-visible)": {
            outline: "none",
          },
        })}
      />
      <SEO title="Homepage" />
      <NavMenuMobile />
      <NavMenuDesktop navPosition="left" />
      <section
        sx={{
          display: "block",
          position: "relative",
          height: ["auto", "100vh"],
        }}
      >
        <Box
          sx={{
            width: "100vw",
            display: ["none", "flex"],
            justifyContent: "center",
          }}
        >
          <Link to="biog" duration={500} smooth={true}>
            <FaArrowDown
              sx={{
                display: "flex",
                position: "absolute",
                color: "white",
                zIndex: 1000,
                bottom: "5px",
                fontSize: "1.6em",
                opacity: 0.7,
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  opacity: 1,
                  cursor: "pointer",
                },
              }}
            />
          </Link>
        </Box>

        <Box
          sx={{
            position: ["relative", "fixed"],
            height: ["auto", "100vh"],
            width: "100vw",
          }}
        >
          <HomeSlider productions={orderedProductionItems} />
        </Box>
      </section>

      <section
        id="biog"
        sx={{
          position: "relative",
          paddingTop: [0, 0],
        }}
      >
        <Grid
          gap={2}
          columns={[1, "1fr 2fr", "1fr 3fr"]}
          sx={{
            //bg: lighten("primary", 0.35),
            bg: "primaryBG",
          }}
        >
          <section />
          <section>
            <div
              className="home-biog"
              sx={{
                py: [0, 3],
                mx: [3, "auto"],
                paddingRight: [0, 3],
              }}
            >
              <h2
                sx={{
                  color: "primary",
                  fontSize: 4,
                }}
              >
                {data.home.frontmatter.title}
              </h2>
              <Flex
                sx={{
                  display: ["flex", "flex"],
                  flexWrap: ["wrap", "inherit"],
                  justifyContent: "space-between",
                  mx: [0, 2],
                  bg: ["white", "transparent"],
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: data.home.html }}
                  className="biog_text"
                  sx={{
                    bg: "white",
                    width: ["100%", "55%"],
                    px: 4,
                    py: 3,
                    //float: ['left', 'none']
                    //marginRight: '5%'
                    //inline image
                    "& .gatsby-resp-image-wrapper": {
                      display: ["inline-block", "none !important"],
                      float: "right",
                      m: "0 0 1em 0.5em",
                      width: "40%",
                    },
                  }}
                />
                <Img
                  fluid={data.home.frontmatter.image.childImageSharp.fluid}
                  alt="Portia Booroff Headshot"
                  backgroundColor="#ccc"
                  sx={{
                    display: ["none", "block"],
                    width: ["20%", "40%"],
                    border: "1px solid white",
                    // float: ['right', 'none'],
                    mt: ["-15px", "0"],
                  }}
                />
              </Flex>
            </div>
            <div
              sx={{
                bg: "white",
                p: 3,
                mr: 3,
                ml: [3, 0],
                ul: {
                  listStyle: "none",
                  pl: 0,
                  li: {
                    lineHeight: "1.8",
                    mb: 3,
                    span: {
                      mr: 3,
                    },
                  },
                },
              }}
            >
              <ul>
                <li>
                  <span role="img" aria-label="star">
                    ⭐️
                  </span>
                  nominated for Best Actress by New York Film Festival - Room 36
                  - Feature Film
                </li>
                <li>
                  <span role="img" aria-label="star">
                    ⭐️
                  </span>
                  nominated Best Actress in Oy Vey by Back in the Box
                  (California) - Short Film
                </li>
                <li>
                  <span role="img" aria-label="star">
                    ⭐️
                  </span>
                  nominated for Best Feminist Film at the London City Film
                  Awards for her work in Hushed.
                </li>
              </ul>
            </div>
            <div
              className="home-news"
              sx={{
                py: 3,
                paddingRight: [0, 3],
                mx: [3, 0],
              }}
            >
              <h2
                sx={{
                  color: "primary",
                  fontSize: 4,
                }}
              >
                Latest News
              </h2>
              {data.news.edges.map(({ node }) => (
                <HomeNewsItem production={node} key={node.id} />
              ))}
            </div>
            <div
              sx={{
                display: "inline-block",
                pb: 4,
                textAlign: "center",
                margin: "0 auto",
                a: {
                  bg: "primary",
                  color: "white",
                  textDecoration: "none",
                  px: 2,
                  py: 1,
                  "&:hover, &:focus": {
                    bg: "mutedText",
                  },
                },
              }}
            >
              <GatsbyLink to="/news">See All News</GatsbyLink>
            </div>
          </section>
        </Grid>
        <Footer navPosition="left" />
      </section>
    </>
  )
}

export const query = graphql`
  query {
    home: markdownRemark(frontmatter: { templateKey: { eq: "page-home" } }) {
      id
      frontmatter {
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        slider
      }
      html
    }

    productions: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "production" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            excerpt
            productionType
            title
            feat_img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
    }
    news: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "news" } } }
      sort: { fields: frontmatter___order, order: DESC }
      limit: 4
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            excerpt
            cropPosition
            thumb {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            title
            feat_img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            date(formatString: "DD MMMM, YYYY")
            order
          }
          fields {
            slug
          }
          html
        }
      }
    }
  }
`

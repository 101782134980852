/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import Img from "gatsby-image"
import BackgroundImg from "gatsby-background-image"

const HomeSlider = ({ productions }) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
  }

  return (
    <>
      <Slider
        {...sliderSettings}
        className="homeSlider"
        sx={{
          bg: "primaryBG",
          "& .slick-dots": {
            display: ["block", "flex !important"],
            position: "absolute",
            top: "0",
            alignItems: "flex-end",
            flexDirection: "column",
            paddingTop: 2,
            "& li button:before": {
              fontSize: "15px",
              color: "primary",
            },
          },
        }}
      >
        {productions.map(item => (
          <div
            sx={{
              position: "relative",
              bg: "primaryBG",
              "& .sliderText": {
                display: ["flex", "block"],
                flexDirection: ["column", "row"],
                position: ["relative", "absolute", null],
                alignContent: "center",
                bottom: ["initial", "1em", null],
                right: ["0", "1em", null],
                color: "white",
                textAlign: "right",
                maxWidth: ["100%", "50%", "40%"],
                textAlign: ["center", "right"],
              },
              "& h2": {
                order: [1, 2],
                display: ["block", "inline-block"],
                py: 1,
                px: 2,
                mt: [0, 3],
                mb: 0,
              },
              "& p": {
                order: [2, 1],
                fontSize: ["0.75em", "1.2em", null],
                display: ["block", "block", null],
                //background: "rgba(0, 0, 0, 0.35)",
                bg: "primary",
                mb: 0,
                mt: 0,
                py: 2,
                px: 3,
                width: "100%",
                fontStyle: "italic",
                //textShadow: "-1px 0px 1px #000000",
                textAlign: ["center", "right"],
                right: "1em",
              },
            }}
          >
            <BackgroundImg
              fluid={item.node.frontmatter.feat_img.childImageSharp.fluid}
              alt={item.node.frontmatter.name}
              sx={{
                mt: ["72px", 0], //because of mobile header
                width: "100vw",
                height: ["50vh", "100vh"],
              }}
            />
            <Flex className="sliderText">
              {item.node.frontmatter.excerpt && (
                <p>{item.node.frontmatter.excerpt}</p>
              )}
              <h2
                sx={{
                  backgroundColor: "primary",
                  "& span": {
                    fontSize: 2,
                    fontWeight: "normal",
                  },
                }}
              >
                {item.node.frontmatter.name}{" "}
                <span>({item.node.frontmatter.productionType})</span>
              </h2>
            </Flex>
          </div>
        ))}
      </Slider>
    </>
  )
}

export default HomeSlider
